//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* topContentsBanner
----------------------------------------------- */
.topContentsBanner{
  $banner:&;
  //
  &__inner{
    position: relative;
    text-align: center;
  }
  &__title{
  }
  &__list{
    display: flex;
    justify-content: flex-start;
    position: relative;
    &:last-child{
      margin-right: 0;
    }
    &Item{
      width: math.div(400,1280)*100%;
      //min-width: 282px;
      background-color: $colorAreaGray;
      margin-left: math.div(40,1280)*100%;;
      &:first-child{
        margin-left: 0;
      }

      a{
        position: relative;
        display: flex;
        &:hover{
          opacity: .5;
        }

        // [ 枠線 ]
        &.is--border{
          &::after{
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            border: solid 1px $colorBorderGray;
          }
        }
      }
      img{
        //min-height: math.div(230,1360)*100vw;//レイアウトシフト対策
        //min-height: 162px;
        aspect-ratio: 800 / 460;
      }

    }
  }
  &__listControl{
    display: none;
  }
  // [ SWiper ]
  .swiper-container{
    #{$banner}{
      &__list{
        min-height: math.div(230,1360)*100vw;
        &Item{
          margin-left: 0;    
        }
      }
    }
    + #{$banner}{
      &__listControl{
        display: inline-flex;
        justify-content: center;
        position: relative;
        padding: 0 48px;
        
        #{$banner}{
          &__listControlButton {
            background-image: none;
            margin: 0;
            @include linkButtonCircleArrow(
              $color:#000,
              $pcW:24,
              $pcArrowW:10,
              $pcArrowRect:5,
            );
            &--prev{
              transform: scale(-1);
              left: 0;
              right: auto;
  
            }
            &--next{
              left: auto;
              right: 0;
  
              &::after{
              }
            }
          }
          &__pagination {
            padding: 24px 0 0;
            position: relative;
            display: flex;
            justify-content: center;
            width: 100%;
            gap: 12px;
          }
          .swiper-pagination-bullet {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            margin: 0 12*.5px;
          }
          .swiper-pagination-bullet-active {
            background: $zoffBlue;
          }
        }
      }
    }
  }
  //
}