//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* topTopics
----------------------------------------------- */
.topTopics{
  $Topics:'.topTopics';
  //
  &__inner{
    position: relative;
    padding: 0 40px;
  }
  &__title{}
  &__list{
    &Item{
      $Item:&;
      //
      border-bottom: solid 1px $colorBorderGray;
      &:first-of-type{
        border-top: solid 1px $colorBorderGray;
      }
      a{
        position: relative;
        display: flex;
        align-items: center;
        padding: 20px 0;
        @include transition(0.4s,ease,0s);
        &:hover{
          opacity: 1;
          color: $zoffBlue;
          #{$Item}Arrow{
            color: #fff;
            background-color: $zoffBlue;
            border-color: $zoffBlue;
            &::after{
              border-color: #fff;
            }
          }
        }
      }
      &Face{
        display: block;
        flex-basis: math.div(120,1280)*100%;
        background-color: #fff;
        margin-right: 20px;
        display: none;
      }
      &Body{
        &--date{
          font-size: 1.3rem;
          font-family: $font-en;
        }
      }
      &Text{
        font-size: 1.5rem;
        padding-top: 10px;
      }
      &Arrow{
        @include linkButtonCircleArrow(
          $color:#000,
          $pcW:34,
          $pcArrowW:10,
          $pcArrowRect:6,
        );
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  &__button{
    &Wrapper{
      padding-top: 40px;
    }
  }
}