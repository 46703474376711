//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* topMagazine
----------------------------------------------- */
.topMagazine{
  $Magazine:'.topMagazine';
  //
  background-color: $colorAreaGray;
  &__inner{
    position: relative;
    padding: 0 0 0 40px;
    overflow: hidden;
    
  }
  .swiper-container{
  }
  &__header{
    position: relative;

    #{$Magazine}__list{
      &Control{ //Swiper用コントロールボタン
        position: absolute;
        right: 40px;
        top: 50%;
        transform: translateY(-50%);
        width: 78px;
        height: 34px;
        &Button{//Prev,Next
          background: #fff;//@override swiper.min.css
          margin-top: 0;//@override swiper.min.css
          top: 0;//@override swiper.min.css
          @include linkButtonCircleArrow(
            $color:#000,
            $pcW:34,
            $pcArrowW:10,
            $pcArrowRect:6,
          );
          &--prev{
            transform: scale(-1,1);
            left: 0;
          }
          &--next{
            right: 0;
          }
          &:hover{
            background-color: $zoffBlue;
            border-color: $zoffBlue;
            &::after{
              border-color: #fff;
            }
          }
        }
      }
    }
  }
  &__title{}
  .swiper-container{
  }
  &__list{
    display: flex;
    justify-content: space-between;
    position: relative;
    &Item{
      $Item:&;
      //
      margin-right: 20px;
      &.swiper-slide{
        width: math.div(350,1360)*100vw;
      }
      
      a{
        position: relative;
        display: flex;
        flex-direction: column;
        opacity: 0;
        @include transition(0.4s,ease,0s);
        .swiper-container-initialized &{
          opacity: 1;
          &:hover{
            opacity: .5;
          }
        }
      }
      &Face{
        display: block;
        width: 100%;
        background-color: #fff;
      }
      &Head{
        display: flex;
        align-items: center;
        font-family: $font-en;
        padding-top: 12px;
        &--date{
          font-size: 1.3rem;
        }
        &--category{
          background-color: $zoffBlue;
          color: #fff;
          font-size: 1.2rem;
          display: inline-flex;
          padding: 2px 8px;
          margin-left: auto;
          height: 21px;
        }
      }
      &Body{
        display: flex;
        padding-top: 12px;
      }
      img{
        width: 100%;
        height: math.div(234,1360)*100vw;//レイアウトシフト対策
        object-fit: cover;
      }
      &Text{
        font-size: 1.5rem;
        line-height: math.div(22,15);

      }
    }
    
  }
}