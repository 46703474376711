//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* topAnnounceTicker
----------------------------------------------- */
.topAnnounceTicker {
  &__inner.swiper-container{
    border: solid 1px #000;
    border-left: none;
    border-right: none;
    padding: 10px 0;
    margin: 0 auto;
    position: relative;
  }
  &__list{
    //padding: 0 40px;
    text-align: center;
    &Item{
      a{
        font-size: 1.5rem;
        font-weight: $fwMid;
        display: inline-flex;
        align-items: center;
        @include linkButtonArrow(#000,$pcArrowRect:6);
        &::after{
          margin-left: 15px;
        }
        &:hover{
          color: $zoffBlue;
          opacity: 1;
          &::after{
            border-color: $zoffBlue;
          }
        }
      }
    }
  }
  &__button{
    $buttonSize:15;
    //
    display: none;
    &.swiper-button-next,&.swiper-button-prev{
      width: $buttonSize*1px;
      height: $buttonSize*1px;
      border: none;
      background: none;
      padding: 0;
      margin-top: 0;
      transform: translateY(-50%);
    }
    &::before{
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      border-right: solid 3px $colorLightGray;
      border-top: solid 2px $colorLightGray;
    }
    &--prev{
      &.swiper-button-prev{
        left: 15px;
        &::before{
          transform: rotate(45deg) scale(-1,-1);
        }
      }
    }
    &--next{
      &.swiper-button-next{
        right: 15px;
        &::before{
          transform: rotate(45deg);
        }
      }
    }
  }
  //
  // [ SubTop ]
  .zoffsubtop & {
    &__inner{
      padding: 30px 0;
    }
  }
  //
}