//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* topLens
----------------------------------------------- */
.topLens{
  $Lens:'topLens';
  //
  background-color: $colorAreaGray;
  &__inner{
    position: relative;
  }
  &__title{}
  &__list{
    display: flex;
    justify-content: flex-start;
    position: relative;
    &Item{
      $Item:&;
      //
      width: calc( (math.div(200+16,1280)*100%) - 16px );//margin:16pxを考慮
      position: relative;
      margin-left: 16px;
      &:nth-child(1){
        margin-left: 0;
      }
      a{
        background-color: #fff;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        padding-bottom: 75px;
        &:hover{
          opacity: 1;
          #{$Item}Arrow{
            color: #fff;
            background-color: $zoffBlue;
            border-color: $zoffBlue;
            &::after{
              border-color: #fff;
            }
          }
        }
      }
      &Face{
        min-height: math.div(156,1360)*100vw;//レイアウトシフト対策
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        img{
          height: math.div(100,1360)*100vw;
        }
        &--blc{
          img{}
        }
        &--color{}
        &--2way{}
        &--polarizedlens{}
        &--clear{}
        &--shc{}
      }
      &Body{
        padding: 0 calc( 24/200 * 100% );
        width: 100%;
      }
      img{}
      &Text{
        font-size: 1.5rem;
        font-weight: $fwMid;
        line-height: math.div(22,15);
        @include transition(0.4s,ease,0s);
        @media screen and (max-width: 980px) {
          font-size: calc( 16 /980 * 100vw );
        }
      }
      &Note {
          font-size:1.2rem;
          color:#f53c3c;
          padding-top: 3px;
          display: block;
      }
      &Arrow{
        position: absolute;
        right: 16px;
        bottom: 16px;
      }
    }
  }
}