//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* topCollection
----------------------------------------------- */
.topCollection{
  $Category:'topCollection';
  //
  &__inner{
    padding: 0 20px;
  }
  &__title{}

  &__button{
    &Wrapper{
      padding-top: 30px;
    }
  }
  //
  //
  //[ Visumo / User ]
  .ecbn-selection-widget{
    min-height: math.div(167,1360)*100vw;
    margin: 0;
    &.initailized{
    }

    // ギャラリー部分以外は非表示
    .ecbn-selection-header,
    .ecbn-selection-header2,
    .link_gallery,
    .ecbn-selection-footer,
    .ecbn-selection-footer2{
      display: none;
    }

    // ギャラリー大枠
    .ecbn-selection-content{
      padding: 0;
    }

    // ギャラリー親要素
    .ecbn-selection-snap{
      display: flex;
      max-width: 100%;
      width: 100%!important;
      height: auto !important;
    }
    // ギャラリー個別
    .ecbn-selection-item{
      position: static !important;
      flex-basis: math.div(167,1360)*100vw;
      margin: 0 3px;
      padding: 0;
      @include transition(.4s,ease,0s);
      &:hover{
        opacity: .5;
      }
      &:nth-of-type(n+9){
        display: none !important;
      }
    }
    // ギャラリー画像
    .ecbn-selection-image{
      object-fit: cover;
      height: math.div(167,1360)*100vw;
      width: 100%;
    }


  }
  //
  //[ Visumo / Staff ]
  .hacobune-top{
    position: relative;
    min-height: math.div(167,1360)*100vw;

    // ギャラリー部分以外は非表示
    .hacobune-layout-base-top-html,
    .hacobune-layout-base-bottom-html{
      display: none;
    }

    // ギャラリー大枠
    .hacobune-layout-base-main-contents{}
    .hacobune-app-container{
      padding: 0;
      max-width: 100%;
    }
    // ギャラリー親要素
    .hacobune-grid-row{
      flex-wrap: nowrap;
      //justify-content: space-between;
    }
    // ギャラリー個別枠
    .hacobune-grid-col{
      flex: auto;
      flex-basis: math.div(167,1360)*100vw;
      margin: 0 3px;
      @include transition(.4s,ease,0s);
      &:hover{
        opacity: .5;
      }
    }
    // ギャラリー個別
    .hacobune-media-post-list-item{
      padding: 0;
    }
    .hacobune-media-post-list-item-img{
      object-fit: cover;
      width: math.div(167,1360)*100vw;
      height: math.div(167,1360)*100vw;
    }
    // ローダー
    .hacobune-infinite-loading{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      z-index: -1;
    }
    // Visumoロゴ
    .hacobune-logo{
      display: none;
    }

    // キャプション
    .hacobune-media-post-list-item-caption{
      padding-top: 8px;
    }
    // 店舗名
    .hacobune-media-post-list-item-shopname{
      font-size: 1.3rem;
      line-height: math.div(19,13);
      display: flex;
      align-items: flex-start;
      > span{
        flex-basis: calc( 100% - #{(14+9)*1px} );
      }
      &::before{
        content: "";
        flex-basis: 14px;
        width: 14px;
        height: 15px;
        display: inline-flex;
        background: no-repeat center top;
        background-image: url(/img/private/common/header/icon_home.svg);
        background-size: 14px 15px;
        margin-right: 9px;
      }
    }
    // スタッフ名
    .hacobune-media-post-list-item-nickname{
      padding-top: 6px;
      font-size: 1.2rem;
      line-height: math.div(17,12);
      &::after{
        content: " さん";
      }
    }

  }
  // [ ユーザーコレクション ]
  &--user{}
  // [ スタッフコレクション ]
  &--staff{}
  //
}
//
//[ Visumo / User ]
// ギャラリーモーダル
.ecbn-selection-preview {
  .ecbn-selection-preview-wrapper{
    .ecbn-selection-preview-close{
      font-size: 56px;
      top: 14px;
      right: -30px;
    }
  }
}