//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* topTagCloud
----------------------------------------------- */
.topTagCloud{
  $Category:'topTagCloud';
  //
  background-color: $colorAreaGray;
  &__inner{
    position: relative;
  }
  &__title{}
  &__list{
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: -16px;//複数行対策
    &Item{
      $Item:&;
      //
      margin-right: 16px;
      margin-bottom: 16px;//複数行対策
      a{
        background-color: #fff;
        position: relative;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        &:hover{
          opacity: 1;
          background-color: $zoffBlue;
          #{$Item}Text{
            color: $zoffBlue;
            color: #fff;
          }
        }
      }
      &Text{
        font-size: 1.7rem;
        line-height: math.div(25,17);
        @include transition(0.4s,ease,0s);
      }
    }
  }
}