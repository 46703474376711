//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* topCategory
----------------------------------------------- */
.topCategory{
  position: relative;
  z-index: 1;
  $Category:'topCategory';
  //
  background-color: $colorAreaGray;
  &__inner{
    position: relative;
  }
  &__title{}
  &__list{
    display: flex;
    justify-content: flex-start;
    position: relative;
    &Item{
      $Item:&;
      //
      width: calc( (math.div(200+16,1280)*100%) - 16px );//margin:16pxを考慮
      margin-left: 16px;
      &:nth-child(1){
        margin-left: 0;
      }
      a{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: solid 1px #000;
        background-color: #fff;
        height: 100%;
        &:hover{
          opacity: 1;
          border-bottom: solid 1px $zoffBlue;
          img{
            opacity: .5;
          }
          #{$Item}Text{
            color: $zoffBlue;
          }
        }
      }
      &Face{
        display: block;
        width: 100%;
        position: relative;
        &--image{}
        &--text{
          position: absolute;
          left: 0;
          bottom: 0;
          display: block;
          padding: math.div(14,1360)*100vw math.div(16,1360)*100vw;
          color: #fff;
          text-shadow: 1px 1px 0 #000;
          font-size: 1.5rem;
          line-height: math.div(22,15);
        }
      }
      &Body{
        padding: 12px;
      }
      img{
        width: 100%;
        min-height: calc( (math.div(314+16,1280)*100%) - 16px );//レイアウトシフト対策
        @include transition(0.4s,ease,0s);
      }
      &Text{
        font-size: 1.6rem;
        line-height: math.div(22,16);
        @include transition(0.4s,ease,0s);
        @media screen and (max-width: 980px) {
          font-size: calc( 16 /980 * 100vw );
        }
      }
    }
  }
  &__buttonWrapper {
    padding-top: 40px;
  }
  &__button {
    min-width: 250px;
  }
}