//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* topMvSlide
----------------------------------------------- */
.topMvSlide {
  $topMvSlide:".topMvSlide";
  //
  min-height: calc(576/1360*100vw);//レイアウトシフト対策（Swiper>SlidesPerView値によって変える）
  overflow: hidden;
  margin-bottom: 40px;
  &__container{
    *{
      opacity: 0;
      will-change: opacity;
    }
    @include transition(0.4s,ease,0s);
    background-color: #fff;
    &.swiper-container-initialized{
      background-color: transparent;
      *{opacity: 1;}
    }
  }
  &__wrapper{
    display: flex;
    flex-wrap:nowrap;
  }
  &__slide{
    position: relative;
    max-height: calc(576/1360*100vw);//レイアウトシフト対策（Swiper>SlidesPerView値によって変える）
    img{
      max-width: 100%;
      height: auto;
    }
  }
  &__link{
    pointer-events: none;
  }
  .swiper-container-initialized{
    #{$topMvSlide}{
      &__slide{
        opacity: 1;
        max-height: none;
      }
      &__link{
        pointer-events: all;
        @include transition(.4s,ease,0s);
        &:hover{
          opacity: .5;
        }
      }
    }
  }
  &__detail {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 20px;
    z-index: 1;
  }
  &__text {
    @include linkButtonArrow(#000,$pcArrowRect:6);
    display: inline-flex;
    align-items: center;
    gap: 0 (math.div( 10 , 1360 ) * 100vw);
    padding: 17px 24px;
    max-width: 65%;
    border: 1px solid #000;
    background-color: #fff;
    box-sizing: border-box;
    &:after {
      flex-shrink: 0;
    }
    span {
      font-size: (math.div( 15 , 1360 ) * 100vw);
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: (math.div( 21 , 15 ) * 1em);
    }
  }
  &__listControlButton {
    .topMvSlide &{
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2225.591%22%20height%3D%2249.061%22%20viewBox%3D%220%200%2025.591%2049.061%22%3E%3Cpath%20d%3D%22M-62.04-15726.607l-1.061-1.061%2C23.47-23.472-23.47-23.468%2C1.061-1.061%2C24.53%2C24.528-.53.53Z%22%20transform%3D%22translate(-37.51%20-15726.607)%20rotate(180)%22%20fill%3D%22%23000000%22%2F%3E%3C%2Fsvg%3E");
      //background-position: 1px 2px;
      background-size: 24px 50px;
      width: 24px;
      height: 50px;
      &::after{
        content: "";
        display: block;
        width: inherit;
        height: inherit;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2225.591%22%20height%3D%2249.061%22%20viewBox%3D%220%200%2025.591%2049.061%22%3E%3Cpath%20d%3D%22M-62.04-15726.607l-1.061-1.061%2C23.47-23.472-23.47-23.468%2C1.061-1.061%2C24.53%2C24.528-.53.53Z%22%20transform%3D%22translate(-37.51%20-15726.607)%20rotate(180)%22%20fill%3D%22%23ffffff%22%2F%3E%3C%2Fsvg%3E");
        background-size: 24px 50px;
        position: relative;
        left: -1px;
        top: -2px;
      }
    }
    &--prev{
      .topMvSlide &{
        left: 30px;
        right: auto;
      }
    }
    &--next{
      .topMvSlide &{
        transform: scale(-1);
        left: auto;
        right: 30px;
        &::after{
          left: 0;
          top: 2px;
        }
      }
    }
  }
  &__pagination {
    .topMvSlide &{
      padding: 24px 0 0;
      margin-bottom: 0;
      position: relative;
      bottom: 0;
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
  .swiper-pagination-bullet {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin: 0 12*.5px;
    opacity: .2;
  }
  .swiper-pagination-bullet-active {
    background: $zoffBlue;
    opacity: 1;
  }
}