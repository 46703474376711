//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

@mixin smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-print-color-adjust: exact;
}

/* topContents
----------------------------------------------- */
.topContents{
  padding: 30px 0 60px;
  position: relative;
  z-index: 1;

  // [ インナー ]
  &__inner{
    padding: 0 40px;
  }

  // [ タイトル ]
  &__title{
    margin-left: 20px;
    margin-bottom: 20px;
    padding-left: 20px;
    position: relative;
    &--en{
      @include font-en;
      @include smoothing;
      font-size: 2.6rem;
    }
    &--ja{
      font-size: 1.2rem;
      padding-left: 10px;
    }
    &::after{
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      background-color: $zoffBlue;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  // [ セパレータ ]
  &__separate{
    position: relative;
    &:before {
      display: block;
      content: "";
      width: 100%;
      border-top: solid 1px #000;
    }
  }

  // [ ボタン ]
  /*
  &__button{
    &Wrapper{
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 60px;
    }
    //
    font-size: 1.3rem;
    font-weight: $fwMid;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    min-height: 55px;
    background-color: #fff;
    border-radius: 55px;
    border: solid 1px #000;
    @include linkButtonArrow(#000,$pcArrowRect:6);
    &::after{
      margin-left: 12px;
    }
    &:hover{
      opacity: 1;
      background-color: $zoffBlue;
      border-color: $zoffBlue;
      color: #fff;
      &::after{
        border-color: #fff;
      }
    }
  }
  */
  &__button {
    &Wrapper {
      padding-top: 60px;
      text-align: center;
    }
    @include linkButtonArrow(#000,$pcArrowRect:6);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 200px;
    min-height: 55px;
    font-size: 1.3rem;
    font-weight: 500;
    border: 1px solid #000;
    background-color: #fff;
    border-radius: 100vmax;
    &:after {
      margin: 0 -13px 0 9px;
    }
    &:hover{
      opacity: 1;
      background-color: $zoffBlue;
      border-color: $zoffBlue;
      color: #fff;
      &::after{
        border-color: #fff;
      }
    }
  }

  // [ 丸アロー ]
  &__linkArrow{
    @include linkButtonCircleArrow(
      $color:#000,
      $pcW:40,
      $pcArrowW:10,
      $pcArrowRect:6,
    );
  }

}