//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* topRanking
----------------------------------------------- */
.topRanking{
  //
  $heightTabBase:40;
  $ranking:&;
  //
  &__inner{
    position: relative;
  }
  &__title{}

  // [ タブボタン ]
  &__tabList{
    display: inline-flex;
    justify-content: center;
    background-color: $colorBg;
    border-radius: $heightTabBase*1px;
    margin-bottom: 20px;
    &Item{
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      padding: 0 30px;
      border-radius: $heightTabBase*1px;
      height: $heightTabBase*1px;
      cursor: pointer;
      @include transition(.4s,ease,0s);
      &:hover,&.js-current{
        color: #fff;
        background-color: #000;
        opacity: 1;
      }

    }
  }

  // [ タブコンテンツ ]
  @keyframes animRankingFadein {
    0% {
      opacity: 0;
      filter: blur(20px);
    }
    100% {
      opacity: 1;
      filter: blur(0);
    }
  }
  &__tabGroup{}
  &__tabContents{
    $tabContent:&;
    //
    display: none;
    //filter: blur(10px);
    @include transition(.4s,ease,0s);
    &.js-current{
      display: block;
      animation: animRankingFadein .5s ease;
    }
    &Ranking{
      min-height: math.div(240,1360)*100vw;//レイアウトシフト対策
    }
    // [ API出力前スケルトン ]
    @keyframes animationSkeletonLoading {
      0% {
        background-position: 100% 0;
      }
      50%, 100% {
        background-position: -100% 0;
      }
    }
    #{$ranking}__skeleton{
      --skeleton-bg-colorBase: #f0f0f0;
      --skeleton-bg-colorFront: rgba(240, 240, 240, 0.5);
      //
      display: flex;
      justify-content: space-between;
      width: 100%;
      min-height: math.div(240,1360)*100vw;//レイアウトシフト対策
      span{
        display: flex;
        height: inherit;
        flex-basis: math.div(200,1360)*100vw;
        //background-color: $colorAreaGray;
        background: linear-gradient(110deg, var(--skeleton-bg-colorBase) 18%, var(--skeleton-bg-colorFront) 28%, var(--skeleton-bg-colorBase) 43%);
        background-size: 200%;
        animation: 2s animationSkeletonLoading linear infinite;
      }
    }
    // [ API出力 ] @override search_results.min.css
    .itemList{
      &__container{
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        padding-top: 0;
        margin-bottom: 0;
      }
      &__contents{
        flex-basis: math.div(200,1360)*100vw;
        min-width: 160px;
        margin: 0;

        // [ 順位/個別 ]
        @for $i from 1 through 10 {
          .foo-#{$i} { width: 10px * $i; }
          &:nth-child(#{$i}){
            .itemList__imgWapper::before,
            .itemList__imgWrapper::before{
              content: "#{$i}";
              @if $i == 1 {
                color: #CBBC28;
              }@else if $i == 2 {
                color: #9F9F9F;
              }@else if $i == 3 {
                color: #99702A;
              }@else{
                color: #D3D3D3;
              }
            }
          }
        }

        // [ 表示個数 ]
        @media screen and (max-width: 1050px) {
          flex-basis: math.div(240,1360)*100vw;
          &:nth-child(6){
            display: none;
          }
        }
      }
      &__link{
        display: flex;
        width: math.div( 154,200 )*100%;
        margin: 0 auto;
        padding-top: math.div( 102,200 )*100%;//レイアウトシフト対策
        position: relative;
        background-color: $colorAreaGray;
        &:hover{
          opacity: .5;
        }
      }
      &__img{
        &Wapper,&Wrapper{ //@typo 正しいスペルも記載しておく
          padding-bottom: 17px;
          position: relative;

          // [ 順位/基本 ]
          &::before{
            content: "1";
            font-size: 2.5rem;
            font-weight: $fwMid;
            font-family: $font-en;
            position: absolute;
            left: 5px;
            top: -2px;
            line-height: math.div(33,25);
          }
        }
        &Contents{
          line-height: 0;
          padding-top: 15px;
          img{
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            min-width: 110px;
          }
        }
      }
      &__text{
        &Wrapper{
        }
        &Contets,&Contents{ //@typo 正しいスペルも記載しておく
        }
      }
      &__name,&__price,&__code{
        text-align: left;
      }
      &__name{
        font-size: 1.5rem;
        line-height: math.div(22,15);
        padding-bottom: 6px;
      }
      &__price{
        font-size: 1.7rem;
        line-height: math.div(22,17);
        padding-bottom: 14px;
      }
      &__tax{
        font-size: 1.0rem;
      }
      &__code{
        font-size: 1.2rem;
        color: $colorTextGray;
      }
    }
    // [ /API出力 ] @override search_results.min.css
  }

}