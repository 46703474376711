//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* topStoreSearch
----------------------------------------------- */
.topStoreSearch{
  $StoreSearch:'.topStoreSearch';
  //
  background-color: $zoffBlue;
  color: #fff;
  margin-top: -80px;
  &__inner{
    position: relative;
    display: flex;
    align-items: center;
  }

  &__face{
    flex-basis: 50%;
    img{
      width: 100%;
      height: auto;
    }
  }
  &__body{
    flex-basis: 50%;
    padding: 20px 60px 0 40px;
    position: relative;
    &::before{
      content: "";
      display: block;
      width: calc(100% - 60px);
      border-top: 1px solid #fff;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  &__title{
    margin-left: -20px;
    margin-bottom: 30px;
    &::after{
      background-color: #fff;
    }
  }
  &__catch{
    font-size: 2.0rem;
    font-weight: $fwMid;
    margin-bottom: 16px;
  }
  &__text{
    font-size: 1.5rem;
  }

  &__button{
    &Wrapper{
      padding-top: 40px;
      //justify-content: flex-start;
      text-align: left;
    }
    color: #000;
    min-width: 253px;
    &:hover{
      color: #fff;
      border-color: #fff;
      &::after{
        border-color: #fff;
      }
    }
  }
}