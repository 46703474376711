//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

// Functions
@function rem($px) {
  @return math.div( $px , 10 ) * 1rem;
}
@function em($fontsize, $lineheight) {
  @return math.div( $lineheight , $fontsize ) * 1em;
}
@function per($px) {
  @return math.div( $px , 1360 ) * 100vw;
}

/* topCollaboration
----------------------------------------------- */
.topCollaboration{
  $Collaboration:'.topCollaboration';
  //
  &__inner{
    position: relative;
    padding: 0 0 0 40px;
    overflow: hidden;
    &.swiper-container{
      padding-right: 0;
      overflow: hidden;
    }
  }
  &__title{}
  &__list{
    $list:&;
    //
    display: flex;
    justify-content: space-between;
    position: relative;
    &Item{
      $Item:&;
      //
      margin-right: 20px;
      &.swiper-slide{
        width: math.div(596,1360)*100vw;
      }
      background-color: $colorAreaGray;
      .swiper-container-initialized &{
        background-color: #fff;
      }
      a{
        position: relative;
        display: flex;
        opacity: 0;
        @include transition(0.4s,ease,0s);
        .swiper-container-initialized &{
          opacity: 1;
          &:hover{
            opacity: .5;
          }
        }
      }
      &Face{
        display: block;
        width: 100%;
      }
      img{
        width: 100%;
        min-height: math.div(398,1360)*100vw;//レイアウトシフト対策
      }
      &Body{
        position: absolute;
        left: per(20);
        right: per(20);
        bottom: per(20);
        z-index: 1;
      }
      &Text{
        @include linkButtonArrow(#000,$pcArrowRect:5);
        display: inline-flex;
        align-items: center;
        gap: 0 10px;
        padding: per(18) per(24);
        max-width: 100%;
        border: 1px solid #000;
        background-color: #fff;
        box-sizing: border-box;
        &:after {
          flex-shrink: 0;
        }
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: rem(13);
          line-height: em(13, 19);
          @media screen and (max-width: 980px) {
            font-size: calc( 13 /980 * 100vw );
          }
        }
      }
    }
  }
  //
  .swiper-pagination-fraction {
    text-align: left;
    left: 40px;
    bottom: 48px;
    width: auto;
    font-weight: $fwReg;
    margin-bottom: 0;
  }
  .swiper-pagination-current {
    font-size: 3.8rem;
    font-family: $font-ja;
    font-weight: $fwMid;
    color: $zoffBlue;
  }
  .swiper-pagination-total {
    font-size: 2.2rem;
  }
  //
  #{$Collaboration}__listControl{ //Swiper用コントロールボタン
    position: absolute;
    right: 40px;
    top: calc( (math.div(398,1360)*100vw) + 16px );
    width: 78px;
    height: 34px;
    &Button{//Prev,Next
      background: #fff;//@override swiper.min.css
      margin-top: 0;//@override swiper.min.css
      top: 0;//@override swiper.min.css
      @include linkButtonCircleArrow(
        $color:#000,
        $pcW:34,
        $pcArrowW:10,
        $pcArrowRect:6,
      );
      &--prev{
        transform: scale(-1,1);
        left: 0;
      }
      &--next{
        right: 0;
      }
      &:hover{
        background-color: $zoffBlue;
        border-color: $zoffBlue;
        &::after{
          border-color: #fff;
        }
      }
    }
  }
  //
  &__button{
    margin-left: -40px;
  }
}