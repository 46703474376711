//== [ 共通読み込み ]=============
//変数・mixin
@use 'module/variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* topVirtualFitting
----------------------------------------------- */
.topVirtualFitting{
  $VirtualFitting:'.topVirtualFitting';
  $faceWidth:math.div(632,1360)*100vw;
  //
  background-color: $colorAreaGray;
  &__inner{
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  &__face{
    flex-basis: $faceWidth;
    img{
      width: 100%;
      height: auto;
    }
    button{
      padding: 0;
    }
  }
  &__body{
    flex-basis: calc( 100% - $faceWidth - 50px );
  }

  &__title{
    &::after{
    }
  }
  &__text{
    font-size: 1.5rem;
  }

}